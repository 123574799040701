<template>
  <div>
    <div class="recipe-search white mb-7">
      <v-form ref="form" v-model="valid" @submit.prevent="searchRecipe">
        <h3>RICERCA RICETTA</h3>
        <div class="d-flex">
          <v-text-field
            color="secondary"
            v-model="s"
            label="Ricerca libera"
            clearable
            append-icon="$search"
          ></v-text-field>
          <!-- <v-icon>$search</v-icon> -->
        </div>
        <div class="d-flex">
          <!-- <v-icon>fa-utensils</v-icon> -->
          <v-select
            color="secondary"
            item-color="secondary"
            v-model="course"
            :items="coursesList"
            label="Portata"
            clearable
          ></v-select>
        </div>
        <div class="d-flex">
          <!-- <v-icon>fa-book</v-icon> -->
          <v-autocomplete
            color="secondary"
            item-color="secondary"
            v-model="ingredients"
            :items="ingredientsList"
            label="Ingredienti"
            required
            clearable
          ></v-autocomplete>
        </div>
        <div class="d-flex">
          <!-- <v-icon>fa-flag</v-icon> -->
          <v-select
            color="secondary"
            item-color="secondary"
            v-model="cuisine"
            :items="cuisineList"
            label="Tipo di cucina"
            required
            clearable
          ></v-select>
        </div>
        <v-btn depressed type="submit" large color="primary"
          ><strong>Ricerca ricetta</strong></v-btn
        >
      </v-form>
    </div>
  </div>
</template>
<style scoped lang="scss">
.recipe-search {
  // box-shadow: 0px 3px 6px #00000029;
  border-radius: $border-radius-root;
  padding: 10px;
  .v-btn {
    width: 100%;
  }
}
.suggested-recipes {
  .suggested-recipe {
    .v-image {
      border-radius: $border-radius-root;
      width: 100%;
      height: auto;
    }
    .recipe-title {
      bottom: 0px;
      position: absolute;
      margin: 10px;
      background-color: white;
      opacity: 0.75;
      padding: 5px;
      border-radius: $border-radius-root;
      height: 44px;
      max-height: 44px;
      font-size: 14px;
      color: var(--v-secondary-base);
      font-weight: bold;
      line-height: 1.3;
      overflow: hidden;
      width: 91%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* autoprefixer: off */
    }
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
export default {
  name: "RecipeSearch",
  data() {
    return {
      s: null,
      course: null,
      coursesData: null,
      ingredients: null,
      ingredientsData: null,
      cuisine: null,
      cuisineData: null,
      valid: true
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  computed: {
    coursesList() {
      let tempArr = [];
      for (var i = 0; i < this.coursesData.length; i++) {
        tempArr.push(this.coursesData[i].name);
      }
      return tempArr;
    },
    ingredientsList() {
      let tempArr = [];
      for (var i = 0; i < this.ingredientsData.length; i++) {
        tempArr.push(this.ingredientsData[i].name);
      }
      return tempArr;
    },
    cuisineList() {
      let tempArr = [];
      if (this.cuisineData) {
        for (var i = 0; i < this.cuisineData.length; i++) {
          tempArr.push(this.cuisineData[i].name);
        }
      }
      return tempArr;
    }
  },
  methods: {
    async fetchWpDataIngredients() {
      const result = await CMSService.getCustomPostBySlug("ingredient");
      if (result && result.length) {
        this.ingredientsData = result;
      }
    },
    async fetchWpDataCourse() {
      const result = await CMSService.getCustomPostBySlug("course");
      if (result && result.length) {
        this.coursesData = result;
      }
    },
    async fetchWpDataCuisine() {
      const result = await CMSService.getCustomPostBySlug("cuisine");
      if (result && result.length) {
        this.cuisineData = result;
      }
    },
    searchRecipe() {
      // this.$router.replace({ name: "RecipeList", params: { filters: 100 } });
      let query = {};
      if (this.s != null && this.s.length > 0) {
        query["s"] = this.s;
      }
      if (this.course != null && this.course.length > 0) {
        query["course"] = this.course;
      }
      if (this.ingredients != null && this.ingredients.length > 0) {
        query["ingredients"] = this.ingredients;
      }
      if (this.cuisine != null && this.cuisine.length > 0) {
        query["cuisine"] = this.cuisine;
      }

      if (this.changePage == "true") {
        this.$router.push({ name: "RecipeList", query });
      } else {
        this.$router.replace({ query });
        let prova = this.$route.query;
        console.log("PROVA", prova);
      }
    }
  },
  created() {
    this.coursesData = this.fetchWpDataCourse();
    this.ingredientsData = this.fetchWpDataIngredients();
    this.cuisineData = this.fetchWpDataCuisine();
  }
};
</script>
