<template>
  <div>
    <div class="mb-7" v-if="suggestedNews">
      <h3>Articoli in evidenza</h3>
      <div class="suggested-news">
        <div
          v-for="news in suggestedNews"
          :key="news.id"
          class="news-container secondary lighten-5 mb-4"
        >
          <router-link
            :to="{
              name: 'NewsDetail',
              params: { newsName: news.slug }
            }"
          >
            <div class="news">
              <v-img
                alt="immagine ricetta"
                :src="news.featured_image_url"
              ></v-img>
              <div class="news-title">
                <span>{{ news.title.rendered }}</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="mb-7 suggestedRecipe" v-if="suggestedRecipe">
      <h3>Scopri altre ricette</h3>
      <div class="suggested-recipes">
        <div
          v-for="recipe in suggestedRecipe"
          :key="recipe.id"
          class="suggested-recipe mb-4"
        >
          <router-link
            :to="{
              name: 'RecipeDetail',
              params: { recipeName: recipe.slug }
            }"
          >
            <v-img alt="immagine ricetta" :src="recipe.featured_image_url"
              ><div class="recipe-title">
                <span>{{ recipe.title.rendered }}</span>
              </div></v-img
            >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.suggested-recipes {
  .suggested-recipe {
    box-shadow: 0px 3px 6px #00000029;
    .v-image {
      border-radius: $border-radius-root;
      width: 100%;
      height: auto;
    }
    .recipe-title {
      bottom: 0px;
      position: absolute;
      margin: 10px;
      background-color: white;
      opacity: 0.75;
      padding: 5px;
      border-radius: $border-radius-root;
      height: 44px;
      max-height: 44px;
      font-size: 14px;
      color: var(--v-secondary-base);
      font-weight: bold;
      line-height: 1.3;
      overflow: hidden;
      width: 91%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      /* autoprefixer: off */
    }
  }
}
.suggested-news {
  .news-container {
    .news {
      box-shadow: 0px 3px 6px #00000029;
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-root;
      background-color: var(--v-grey) !important;
      .v-image {
        border-radius: 4px 4px 0px 0px;
        max-width: 100%;
      }
      .news-title {
        border-radius: 0px 0px 4px 4px;
        // background-color: white;
        margin: 6px 10px;
        height: 54px;
        max-height: 54px;
        font-size: 14px;
        color: var(--v-secondary-base);
        font-weight: bold;
        line-height: 1.3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
      }
    }
  }
}
</style>
<script>
import CMSService from "~/service/cmService";
export default {
  name: "SuggestedRecipeNews",
  props: {
    showNews: { type: String, default: "true" },
    showRecipe: { type: String, default: "true" }
  },
  data() {
    return {
      suggestedRecipe: null,
      suggestedNews: null
    };
  },
  methods: {
    async fetchSuggestedRecipe() {
      let params = [];
      params.push({
        filterName: "per_page",
        filterValue: 4
      });
      let res = await CMSService.getCustomPostByFilters("recipe", params);
      this.suggestedRecipe = res.data;
    },
    async fetchSuggestedNews() {
      let params = [];
      params.push({
        filterName: "per_page",
        filterValue: 4
      });
      let res = await CMSService.getCustomPostByFilters("posts", params);
      this.suggestedNews = res.data;
    }
  },
  created() {
    if (this.showRecipe == "true") {
      this.suggestedRecipe = this.fetchSuggestedRecipe();
    }
    if (this.showNews == "true") {
      this.suggestedNews = this.fetchSuggestedNews();
    }
  }
};
</script>
