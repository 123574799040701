var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.suggestedNews)?_c('div',{staticClass:"mb-7"},[_c('h3',[_vm._v("Articoli in evidenza")]),_c('div',{staticClass:"suggested-news"},_vm._l((_vm.suggestedNews),function(news){return _c('div',{key:news.id,staticClass:"news-container secondary lighten-5 mb-4"},[_c('router-link',{attrs:{"to":{
            name: 'NewsDetail',
            params: { newsName: news.slug }
          }}},[_c('div',{staticClass:"news"},[_c('v-img',{attrs:{"alt":"immagine ricetta","src":news.featured_image_url}}),_c('div',{staticClass:"news-title"},[_c('span',[_vm._v(_vm._s(news.title.rendered))])])],1)])],1)}),0)]):_vm._e(),(_vm.suggestedRecipe)?_c('div',{staticClass:"mb-7 suggestedRecipe"},[_c('h3',[_vm._v("Scopri altre ricette")]),_c('div',{staticClass:"suggested-recipes"},_vm._l((_vm.suggestedRecipe),function(recipe){return _c('div',{key:recipe.id,staticClass:"suggested-recipe mb-4"},[_c('router-link',{attrs:{"to":{
            name: 'RecipeDetail',
            params: { recipeName: recipe.slug }
          }}},[_c('v-img',{attrs:{"alt":"immagine ricetta","src":recipe.featured_image_url}},[_c('div',{staticClass:"recipe-title"},[_c('span',[_vm._v(_vm._s(recipe.title.rendered))])])])],1)],1)}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }